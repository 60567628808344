import './Team.css';
import React from "react";
import sofya from "./avatar/ava-Sofya-artdirector.jpg";
import roman from "./avatar/ava-Roman-IT.jpg";
import anna from "./avatar/ava-Anya.jpg";
import petr from "./avatar/ava-Petr-zvuk.jpg";
import evgeniya from "./avatar/ava-Evgeniya-media.jpg";
import viktoria from "./avatar/ava-Viktoriya-bukhgalter.jpg";
import igor from "./avatar/ava-Igor-golos.jpg";


export default function Team(){
    return (
        <div className="team">
            <div className="team_top-gradient"/>
            <div className="row team_h2 p0-m0">
                <div className="col-12">
                    <h2 className="team_h2_text">Команда</h2>
                </div>
            </div>
            <div className="row p0-m0">
                <div className="team_wrapper-avatar col-12 col-sm-6 col-md-4 col-lg-3 offset-lg-1">
                    <img className="img-fluid" src={anna} alt="Анна Савинкова"/>
                    <h3>Анна Савинкова</h3>
                    <p>Организатор и координатор</p>
                </div>
                <div className="team_wrapper-avatar col-12 col-sm-6 col-md-4 col-lg-3">
                    <img className="img-fluid" src={sofya} alt="Софья Мироедова"/>
                    <h3>Софья Мироедова</h3>
                    <p>арт-директор</p>
                </div>
                <div className="team_wrapper-avatar col-12 col-sm-6 offset-sm-3 col-md-4 offset-md-0  col-lg-3 offset-lg-0">
                    <img className="img-fluid" src={viktoria} alt="Виктория Мацко"/>
                    <h3>Виктория Мацко</h3>
                    <p>Бухгалтер</p>
                </div>
            </div>

            <div className="row p0-m0">
                <div className="team_wrapper-avatar col-12 col-sm-6 col-md-4 col-lg-3">
                    <img className="img-fluid" src={petr} alt="Пётр Исаев"/>
                    <h3>Пётр Исаев</h3>
                    <p>Саунд-дизайнер</p>
                </div>
                <div className="team_wrapper-avatar col-12 col-sm-6 col-md-4 col-lg-3">
                    <img className="img-fluid" src={igor} alt="Игорь Музалевский"/>
                    <h3>Игорь Музалевский</h3>
                    <p>VUI-дизайнер</p>
                </div>
                <div className="team_wrapper-avatar col-12 col-sm-6 col-md-4 col-lg-3">
                    <img className="img-fluid" src={evgeniya} alt="Евгения Фомина"/>
                    <h3>Евгения Фомина</h3>
                    <p>Медиа</p>
                </div>
                <div className="team_wrapper-avatar col-12 col-sm-6 col-md-4 offset-md-4 col-lg-3 offset-lg-0">
                    <img className="img-fluid" src={roman} alt="Роман Симанженков"/>
                    <h3>Роман Симанженков</h3>
                    <p>IT</p>
                </div>
            </div>
            <div className="team_bottom-gradient"/>
        </div>
    );
}