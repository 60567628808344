import './Footer.css';
import React from 'react';
import justai from './icons/JustAI/JustAI.png';
import maria from './icons/Maria/Maria.png';
import sberDevices from './icons/SberDevices/BadgeSD_Partner_Black_ru@2x.png';
import unistar from './icons/Unistar/Unistar.png';
import yandex from './icons/Yandex/Yandex.png';

export default function Footer(){
    return (
        <div className="row footer p0-m0">
                <div style={{paddingBottom: '5vh'}} className="col-12">
                    <img className="img-fluid" src={justai} alt="justai"/>
                    <img className="img-fluid" src={maria} alt="maria"/>
                    <img style={{width: '12%'}} className="img-fluid" src={sberDevices} alt="sberDevices"/>
                    <img className="img-fluid" src={unistar} alt="unistar"/>
                    <img className="img-fluid" src={yandex} alt="yandex"/>
            </div>

            <div className="footer_contacts col-12">
                <div className="col-12">
                    <a href="https://t.me/justvoicegames" target="_blank" rel="noreferrer" >Telegram</a>
                    <a href="https://vk.com/botforeverbot" target="_blank" rel="noreferrer" >Вконтакте</a>
                    <a href="mailto:hello@voicegame.ru">hello@voicegame.ru</a>
                    <a href="tel:+79525193998">8 - 952 - 519 - 39 - 98</ a>
                    <button className="footer_contacts_button" data-toggle="modal" data-target="#policyModal">Политика конфиденциальности</button>
                </div>
            </div>


            <div className="modal fade" id="policyModal" tabindex="-1" role="dialog" aria-labelledby="policyModalLabel" aria-hidden="true">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div style={{textAlign: 'center', width: '100%'}}>
                                <h5 id="policyModalLabel">ПОЛИТИКА
                                    В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ
                                </h5>
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>от «22» апреля 2021 г.</p>
                            <h6>1. ОБЩИЕ ПОЛОЖЕНИЯ</h6>
                            <p>1.1. Политика в отношении обработки персональных данных пользователей (далее — Политика) — документ, устанавливающий взаимоотношения между  физическими лицами - субъектами персональных данных (далее — «Пользователь») и  ИП Савинкова Анна Юрьевна (ОГРНИП: 320332800015900, ИНН: 332904460531,  адрес:600032, г. Владимир, ул. Комиссарова, 41-96 (далее — «Оператор» либо «Компания») в части обработки персональных данных. </p>
                            <p>1.2. Настоящая Политика действует в отношении всей информации, которую  Компания и/или ее аффилированные лица, включая лиц, входящих в одну группу с  Компанией, могут получить о Пользователе во время использования им любого из  сайтов, сервисов, служб, программ и продуктов Компании (далее — «Сервисы»).  Согласие пользователя на предоставление персональной информации, данное им в  соответствии с настоящим Положением в рамках отношений с одним из лиц, входящих  в группу лиц Компании, распространяется на всех лиц, входящих в группу лиц  Компании. </p>
                            <p>1.3. Политика разработана в соответствии с п. 2 ч. 1 ст. 18.1 Федерального закона  от 27.07.2006 г. № 152-ФЗ «О персональных данных» (далее — ФЗ «О персональных  данных»). Политика содержит сведения, подлежащие раскрытию в соответствии с ч. 1  ст. 14 ФЗ «О персональных данных», является общедоступным документом и  расположена по адресу https://voicegame.ru/ (далее — «Сайт Компании»). </p>
                            <h6>2. ПРАВА ПОЛЬЗОВАТЕЛЕЙ </h6>
                            <p>2.1. Пользователи имеют право: </p>
                            <p>2.1.1. на получение персональных данных, относящихся к данному Пользователю,  и информации, касающейся их обработки;
                            </p>
                            <p>2.1.2. на уточнение, блокирование или уничтожение его персональных данных в  случае, если они являются неполными, устаревшими, неточными, незаконно  полученными или не являются необходимыми для заявленной цели обработки;
                            </p>
                            <p>2.1.3. на отзыв данного им согласия на обработку персональных данных; 2.1.4. на защиту своих прав и законных интересов, в том числе на возмещение  убытков и компенсацию морального вреда в судебном порядке;</p>
                            <p>2.1.5. на обжалование действий или бездействия Компании в уполномоченный  орган по защите прав субъектов персональных данных или в судебном порядке.
                            </p>

                            <h6>3. ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ</h6>
                            <p>3.1. Компания обрабатывает персональные данные на законной и справедливой  основе для выполнения возложенных законодательством функций, полномочий и  обязанностей, осуществления прав и законных интересов Компании и Пользователей.</p>
                            <p>3.2. Компания получает персональные данные непосредственно от  Пользователей и осуществляет их обработку исключительно с согласия  Пользователей. Компания получает персональные данные Пользователя через  Сервисы.</p>
                            <p>3.3. Базы данных информации, содержащей персональные данные  Пользователей-граждан Российской Федерации, находятся на территории Российской  Федерации.
                            </p>
                            <h6>4. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ</h6>
                            <p>4.1. Настоящая Политика устанавливает обязательства Компании по  неразглашению и обеспечению режима защиты конфиденциальности персональных  данных, которые Пользователь предоставляет при использовании Сервисов.</p>
                            <p>4.2. Компания обрабатывает персональные данные Пользователей с их согласия.  Оставляя свои данные путем заполнения полей «E-mail», «Имя» и нажимая  «Отправить заявку» во время использования Сервисов, Пользователь дает свое согласие  Компании на обработку, в т. ч. на действия, предусмотренные в п. 6.3. Политики. В  случае несогласия с этими условиями пользователь должен воздержаться от  использования Сервисов Компании. В случае если в настоящую Политику будут  внесены какие-либо изменения и дополнения, с которыми Пользователь не согласен,  он обязан прекратить использование Сервисов Компании. </p>
                            <p>4.3. Отзыв согласия на обработку персональных данных может быть осуществлен  путем направления Пользователем соответствующего распоряжения в простой  письменной форме на адрес электронной почты hello@voicegame.ru
                            </p>
                            <p>4.4. Компания обрабатывает персональные данные Пользователей в целях  соблюдения норм законодательства РФ, а также:
                            </p>
                            <p>4.4.1. заключения и исполнения договоров;</p>
                            <p>4.4.2. информирования о новых товарах, услугах;</p>
                            <p>4.4.3. подготовки индивидуальных предложений;</p>
                            <p>4.4.4. ведения рекламной деятельности;</p>
                            <p>4.4.5. предоставления Пользователям доступа к специальной информации; </p>
                            <p>4.4.6. обработки заявок, поданных через Сервисы Компании;</p>
                            <p>4.4.7. ведения бухгалтерского учѐта;</p>
                            <p>4.5. Категории персональных данных, которые Компания собирает для  достижения целей, указанных в п.4.4. Политики:
                            </p>
                            <p>4.5.1. электронная почта; </p>
                            <p>4.5.2. имя;</p>
                            <p>4.5.3. идентификатор пользователя, хранимый в cookie;</p>
                            <p>4.5.4. источник перехода на Сервис Компании; </p>
                            <p>4.5.5. информация поискового или рекламного запроса.
                            </p>
                            <p>4.6. Компания не обрабатывает специальные категории персональных данных  Пользователей, а также биометрические категории персональных данных.
                            </p>
                            <p>4.7. Настоящая Политика применима только к информации, обрабатываемой в  ходе использования Сервисов. Компания не контролирует и не несет ответственность  за обработку информации сайтами и сервисами третьих лиц, на которые Пользователи  могут перейти по ссылкам, доступным внутри Сервисов.
                            </p>
                            <p>4.8. Компания не проверяет достоверность персональных данных,  предоставляемых Пользователем, и не имеет возможности оценивать его  дееспособность. Однако Компания исходит из того, что Пользователи предоставляют  достоверные и достаточные персональные данные и поддерживают их в актуальном  состоянии. Ответственность, а также возможные последствия за предоставление  недостоверных или не актуальных персональных данных, несет Пользователь.
                            </p>
                            <h6>5. ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ
                                ПРИ ПОМОЩИФАЙЛОВ COOKIE
                            </h6>
                            <p>5.1. Файлы cookie, передаваемые Компании техническими устройствами  Пользователей, могут использоваться для предоставления Пользователям  персонализированных функций Сервисов, для персональной рекламы, которая  показывается Пользователям, в статистических и исследовательских целях, а также  для улучшения Сервисов. </p>
                            <p>5.2. Пользователи осознают, что оборудование и программное обеспечение,  используемые ими для посещения сайтов в сети интернет, могут обладать функцией  запрещения операций с файлами cookie (для любых сайтов или для определенных  сайтов), а также удаления ранее полученных файлов cookie.</p>
                            <p>5.3. Компания вправе установить, что предоставление определенных функций  Сервисов возможно лишь при условии, что прием и получение файлов cookie  разрешены Пользователями.</p>
                            <p>5.4. Структура файла cookie, его содержание и технические параметры  определяются Компанией и могут изменяться без предварительного уведомления  Пользователей.</p>
                            <p>5.5. Счетчики, размещенные в Сервисах, могут использоваться для анализа  файлов cookie Пользователей, для сбора и обработки статистической информации об  использовании Сервисов, а также для обеспечения их работоспособности в целом или  отдельных функций в частности. Технические параметры работы счетчиков  определяются Компанией и могут изменяться без предварительного уведомления  Пользователей. </p>
                            <h6>6. УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ</h6>
                            <p>6.1. Обработка персональных данных Пользователей производится до достижения целей обработки либо до момента отзыва согласия Пользователя на  обработку персональных данных.
                            </p>
                            <p>6.2. Компания обрабатывает персональные данные Пользователей  автоматизированным способом, с использованием средств вычислительной техники, а  также неавтоматизированным способом.</p>
                            <p>6.3. Действия по обработке персональных данных включают сбор, запись,  систематизацию, накопление, хранение, уточнение (обновление, изменение),  извлечение, использование, передачу (предоставление, доступ), обезличивание,  блокирование, удаление и уничтожение. </p>
                            <p>6.4. В отношении персональных данных Пользователей сохраняется их  конфиденциальность, кроме случаев добровольного предоставления Пользователями  информации о себе для общего доступа неограниченному кругу лиц.
                            </p>
                            <p>6.5. Компания вправе передать персональные данные Пользователя третьим  лицам в следующих случаях: </p>
                            <p>6.5.1. пользователи выразили согласие на такие действия;</p>
                            <p>6.5.2. передача необходима для использования Пользователями определенных  функций Сервисов либо для исполнения определенного соглашения или договора; </p>
                            <p>6.5.3. передача предусмотрена российским или иным применимым  законодательством в рамках установленной законодательством процедуры;</p>
                            <p>6.5.4. такая передача происходит в рамках продажи или иной передачи бизнеса  (полностью или в части), при этом к приобретателю переходят все обязательства по  соблюдению условий настоящей Политики применительно к полученным им  персональных данных; </p>
                            <p>6.5.5. в результате обработки персональных данных Пользователей путем их  обезличивания получены обезличенные статистические данные, которые передаются  третьему лицу для проведения исследований, выполнения работ или оказания услуг по  поручению Компании;
                            </p>
                            <p>6.5.6. персональные данные Пользователей могут быть переданы  уполномоченным органам государственной власти Российской Федерации по  основаниям и в порядке, установленным действующим законодательством Российской  Федерации.
                            </p>
                            <p>6.6. При обработке персональных данных Пользователей Компания  руководствуется:
                            </p>
                            <p>6.6.1. ФЗ «О персональных данных»;</p>
                            <p>6.6.2. Постановлением Правительства Российской Федерации от 01.11.2012 г. №  1119 «Об утверждении требований к защите персональных данных при их обработке в  информационных системах персональных данных»;
                            </p>
                            <p>6.6.3. Постановлением Правительства Российской Федерации от 15.09.2008 г. №  687 «Об утверждении Положением об особенностях обработки персональных данных,  осуществляемой без использования средств автоматизации»;
                            </p>
                            <p>6.6.4. Приказом ФСТЭК России от 18.02.2013 г. № 21 «Об утверждении Состава и  содержания организационных и технических мер по обеспечению безопасности  персональных данных при их обработке в информационных системах персональных  данных»;
                            </p>
                            <p>6.6.5. Приказом ФСБ России от 10.07.2014 г. № 378 «Об утверждении Состава и  содержания организационных и технических мер по обеспечению безопасности  персональных данных при их обработке в информационных системах персональных  данных с использованием средств криптографической защиты информации,  необходимых для выполнения установленных Правительством Российской Федерации  требований к защите персональных данных для каждого из уровней защищенности». </p>
                            <p>6.7. Компания принимает необходимые организационные и технические меры для  защиты персональных данных Пользователя от неправомерного или случайного  доступа, уничтожения, изменения, блокирования, копирования, распространения, а  также от иных неправомерных действий третьих лиц.
                            </p>
                            <p>6.8. Компания совместно с Пользователем принимает все необходимые меры по  предотвращению убытков или иных отрицательных последствий, вызванных утратой  или разглашением персональных данных.</p>
                            <h6>7. СВЕДЕНИЯ ОБ ОБЕСПЕЧЕНИИ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h6>
                            <p>7.1. Компания назначает ответственного за организацию обработки персональных  данных для выполнения обязанностей, предусмотренных ФЗ «О персональных  данных» и принятыми в соответствии с ним нормативными правовыми актами.</p>
                            <p>7.2. Компания применяет комплекс правовых, организационных и технических мер  по обеспечению безопасности персональных данных для обеспечения  конфиденциальности персональных данных и их защиты от неправомерных действий:</p>
                            <p>7.2.1. устанавливает правила доступа к персональным данным, обрабатываемым  в информационной системе Компании, а также обеспечивает регистрацию и учѐт всех  действий с ними; </p>
                            <p>7.2.2. производит оценку вреда, который может быть причинен Пользователям в  случае нарушения ФЗ «О персональных данных»; </p>
                            <p>7.2.3. производит определение угроз безопасности персональных данных при их  обработке в информационной системе Компании; </p>
                            <p>7.2.4. применяет организационные и технические меры и использует средства  защиты информации, необходимые для достижения установленного уровня  защищенности персональных данных; </p>
                            <p>7.2.5. осуществляет обнаружение фактов несанкционированного доступа к  персональным данным и принимает меры по реагированию, включая восстановление  персональных данных, модифицированных или уничтоженных вследствие  несанкционированного доступа к ним;
                            </p>
                            <p>7.2.6. производит оценку эффективности принимаемых мер по обеспечению  безопасности персональных данных до ввода в эксплуатацию информационной  системы Компании; </p>
                            <p>7.2.7. осуществляет внутренний контроль соответствия обработки персональных  данных ФЗ «О персональных данных», принятым в соответствии с ним нормативным  правовым актам, требованиям к защите персональных данных, Политике, и иным  локальным актам, включающий контроль за принимаемыми мерами по обеспечению  безопасности персональных данных и их уровня защищенности при обработке в  информационной системе Компании.</p>
                            <h6>8. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ </h6>
                            <p>8.1. Компания вправе в любой момент изменить настоящую Политику,  опубликовав соответствующие изменения. Настоящая Политика не может быть  изменена, кроме как посредством опубликования измененного документа на Сайте  Компании. К отношениям между Пользователем и Компанией, возникающим в связи с  применением настоящей Политики, подлежит применению право Российской  Федерации.
                            </p>
                            <p>8.2. Для реализации своих прав и законных интересов Пользователи имеют право  обратиться к Оператору либо направить запрос лично или с помощью представителя  по адресу, указанному в п. 4.3. Политики или через форму обратной связи. Запрос  должен содержать сведения, указанные в ч. 3 ст. 14 ФЗ «О персональных данных».
                            </p>
                        </div>
                        <div className="modal-footer"/>
                    </div>
                </div>
            </div>
        </div>
    );
}