import './Header.css';

export default function Header(){
    return (
        <div className="row header p0-m0 align-items-center">
            <div className="col-12 col-sm-3 col-md-2 col-lg-2 p0-m0">
                <img className="img-fluid" src="./voiceGame.png" alt="VoiceGame"/>
            </div>

            <div className="header_menu col-12  col-sm-9 offset-md-2 col-md-8 offset-lg-3 col-lg-7">
                <a href="#Projects">проекты</a>
                <a href="#About">о нас</a>
                <a href="#Contacts">контакты</a>
            </div>
        </div>
    );
}
