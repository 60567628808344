import './Contact.css';
import iconDevil from './icon-devil.png';
import React from "react";
import emailjs from 'emailjs-com';
import{ init } from 'emailjs-com';
init('user_HBcRQAI215yk2p19omQNx');


export default function Contact(){

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_r3pdn49', 'template_ax9scqm', e.target, 'user_HBcRQAI215yk2p19omQNx')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }

    return (
        <div id="Contacts" className="contact">
            <div className="row contact_h2 p0-m0">
                <div className="col-12">
                    <h2 className="contact_h2_text">Есть проект? Поможем реализовать!</h2>
                </div>
            </div>
            <div className="contact_wrapper row p0-m0">
                <div className="contact_wrapper_icon-wrapper align-self-end">
                    <img className="img-fluid" src={iconDevil} alt="icon"/>
                </div>
                <div className="col-12 col-sm-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
                    <form onSubmit={sendEmail}>
                        <div className="form-group">
                            <label htmlFor="contactInputName">Ваше Имя</label>
                            <input required="required" type="text" className="form-control" id="contactInputName" aria-describedby="emailHelp" name="user_name"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="contactInputEmail">Email</label>
                            <input required="required" type="email" className="form-control" id="contactInputEmail" aria-describedby="emailHelp" name="user_email"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="contactInputPhone">Телефон</label>
                            <input required="required" type="tel" className="form-control" id="contactInputPhone" name="user_phone"/>
                        </div>
                        <div className="form-group">
                            <label htmlFor="contactInputIdea">Ваша идея</label>
                            <textarea className="form-control" id="contactInputIdea" rows="3" name="user_idea"/>
                        </div>
                        <div className="g-recaptcha" data-sitekey="6LfFmssaAAAAAGmCdeihS4YQJ7gbMTCAat_aNFU_"/>
                        <button type="submit" className="btn btn-primary">Отправить</button>

                    </form>
                </div>
            </div>
        </div>
    );
}