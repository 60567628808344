import React from "react";
import './Welcome.css';

export default function Welcome(){
    return (
            <div className="welcome p0-m0">
                <div className="row welcome_gradient align-items-center p0-m0">
                    <div className="welcome_content col-12 col-sm-12 col-md-12 col-lg-5 offset-lg-1">
                        <h1>Игры и навыки для голосовых ассистентов</h1>
                        <h2>Диалоговые технологии для людей и их любимых брендов</h2>
                        <div className="welcome_button">
                            <a className="btn btn-light" href="#Contacts" role="button">Оставить заявку</a>
                        </div>
                    </div>
                </div>
                <div className="welcome_bottom-gradient"/>
            </div>
    );
}