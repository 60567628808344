import './OurAwards.css';
import React from "react";

import alisa from './icons/alisa/Alisa2.png';
import g8 from './icons/g8/G82.png';
import sber from './icons/sber/Sber2.png';
import yandex from './icons/yandex/Yandex2.png';

export default function OurAwards(){
    return (
        <div className="our-awards">
            <div className="our-awards_top-gradient"/>
            <div className="row our-awards_h2 p0-m0">
                <div className="col-12">
                    <h2 className="our-awards_h2_text">Награды и сертификаты</h2>
                </div>
            </div>
            <div className="row our-awards_examples p0-m0">
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-4 col-lg-3 ">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={sber} alt="sber"/>
                        <h3>Хакатон Sber.Code2021</h3>
                        <p>Трек «SberDevices: создание CanvasApp»</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={sber} alt="sber"/>
                        <h3>Хакатон Sber.Code2020</h3>
                        <p>Трек «SberDevices: создание концепта умного устройства»</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={yandex} alt="yandex"/>
                        <h3>Хакатон Яндекс 2020</h3>
                        <p>Победа в хакатоне по разработке навыков для Яндекс.Станции</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={sber} alt="sber"/>
                        <h3>Хакатон Прорыв 2020</h3>
                        <p>Кейс: Почта России</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={g8} alt="g8"/>
                        <h3>G8 2019</h3>
                        <p>Интерактивные проекты</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-4 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={alisa} alt="alisa"/>
                        <h3>Премия Алисы</h3>
                        <p>Лучшие навыки ноября 2019</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-6 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={alisa} alt="alisa"/>
                        <h3>Премия Алисы</h3>
                        <p>Лучшие навыки сентября 2019</p>
                    </div>
                </div>
                <div className="our-awards_examples_wrapper col-12 col-sm-6 col-md-6 col-lg-3">
                    <div className="our-awards_examples_wrapper_example">
                        <img className="img-fluid" src={alisa} alt="alisa"/>
                        <h3>Премия Алисы</h3>
                        <p>Лучшие навыки июля 2019</p>
                    </div>
                </div>
            </div>
        </div>
    );
}