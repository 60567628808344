import React from "react";
import './App.css';

import Header from "./components/Header/Header";
import Welcome from "./components/Welcome/Welcome";
import YourApp from "./components/YourApp/YourApp";
import YourIdea from "./components/YourIdea/YourIdea";
import Profit from "./components/Profit/Profit";
import OurProjects from "./components/OurProjects/OurProjects";
import OurAwards from "./components/OurAwards/OurAwards";
import Team from "./components/Team/Team";
import Submit from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";

export default function App() {

  return (
      <React.Fragment>
          <Header/>
          <Welcome/>
          <YourApp/>
          <YourIdea/>
          <Profit/>
          <OurProjects/>
          <OurAwards/>
          <Team/>
          <Submit/>
          <Footer/>
      </React.Fragment>
  );
}
