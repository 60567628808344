import './OurProjects.css';
import React from "react";
import execute from './img/execute/execute.jpg';
import stronghold from './img/stronghold/stronghold.jpg';
import puzzles from './img/puzzles/puzzles.jpg';
import patrick from './img/patrick/patrick.jpg';
import stronghold2 from './img/stronghold2/stronghold2.jpg';

export default function OurProjects(){
    return (
        <div id="Projects" className="our-projects">
            <div className="our-projects_top-gradient"/>
            <div className="row our-projects_h2 p0-m0">
                <div className="col-12">
                    <h2 className="our-projects_h2_text">Наши проекты</h2>
                </div>
            </div>
            <div className="row p0-m0">
                <div className="our-projects_carousel-wrapper offset-md-2 col-md-8 offset-lg-2 col-lg-8">

                    <div id="carouselOurProjects" className="carousel slide" data-ride="carousel">
                        <div className="carousel-inner">

                            <div className="carousel-item active">
                                <img className="d-block w-100" src={execute} alt="Казнить нельзяпомиловать"/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>Казнить нельзя помиловать</h5>
                                    <p>Попробуйте себя в роли чёртика на левом плече, или же станьте ангелом. Возможно, именно вам придётся стать частью той силы, что вечно хочет зла, но совершает благо?</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={stronghold} alt="Цитадель"/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>Цитадель. Часть первая</h5>
                                    <p> Собирайте предметы для выживания и используйте их, деритесь с врагами, умирайте, воскресайте и ни в чем себе не отказывайте!
                                        Каждое ваше действие будет иметь последствия.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={puzzles} alt="Вежливые загадки"/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>Вежливые загадки</h5>
                                    <p>Отгадывайте загадки под музыку вместе с вашим ребенком, изучайте новые слова и ситуации вместе с вежливыми загадками. Смартап позволит ребенку в игровой форме познакомиться с различными ситуациями на примере животных-персонажей и вариантами возможных реакций на них.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={patrick} alt="Вежливые загадки"/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>Патрик. Помощник бармена</h5>
                                    <p>Посетителю важно быстро сориентироваться во всем многообразии видов и вкусов пива. Теперь это можно сделать, не прибегая, к услугам бармена, а воспользовавшись голосовым помощником Патрика в Алисе или Google Assistant, или же в Телеграм.</p>
                                </div>
                            </div>
                            <div className="carousel-item">
                                <img className="d-block w-100" src={stronghold2} alt="Вежливые загадки"/>
                                <div className="carousel-caption d-none d-md-block">
                                    <h5>Цитадель. Часть вторая</h5>
                                    <p>Превратитесь в котика! Собирайте предметы, побеждайте монстров с помощью караоке, или кусь, и ни в чем себе не отказывайте. Остерегайтесь подкустовных выползней!</p>
                                </div>
                            </div>

                        </div>
                        <a className="carousel-control-prev" href="#carouselOurProjects" role="button"
                           data-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"/>
                            <span className="sr-only">Previous</span>
                        </a>
                        <a className="carousel-control-next" href="#carouselOurProjects" role="button"
                           data-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"/>
                            <span className="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}