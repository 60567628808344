import './YourIdea.css';
import alisa from './icons/voice1/Alisa_Yandex@2x.png';
import salute from './icons/voice2/Sber@2x.png';
import maria from './icons/voice3/Maria@2x.png';
import alexa from './icons/voice4/Alexa@2x.png';

import vk from './icons/message1/vk-logo@2x.png';
import telegram from './icons/message2/Telegram_Messenger@2x.png';
import slack from './icons/message3/Slack@2x.png';
import viber from './icons/message4/viber@2x.png';

import just_ai from './icons/instruments1/Just_AI_logo@2x.png';
// import salute from './icons/instruments2/Sber@2x.png';
import aimylogic from './icons/instruments3/aimylogic_logo@2x.png';
import aimybox from './icons/instruments4/Aimybox@2x.png';


export default function YourIdea(){
    return (
        <div className="your-idea">
            <div className="your-idea_top-gradient"/>
            <div className="row your-idea_h2 p0-m0">
                <div className="col-12">
                    <h2 className="your-idea_h2_text">Реализация на платформах</h2>
                </div>
            </div>
            <div className="row justify-content-center your-idea_examples p0-m0">
                <div className="your-idea_examples_wrapper col-12 col-sm-4 col-md-4 col-lg-4">
                    <p className="your-idea_examples_wrapper_text">Ассистенты</p>
                        <div className="row p0-m0">
                            <div className="col-6 col-lg-4 offset-lg-2">
                                <img className="img-fluid" src={alisa} alt="Алиса"/>
                                <p className="your-idea_examples_wrapper_company_text">Алиса</p>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img className="img-fluid" src={salute} alt="Салют"/>
                                <p className="your-idea_examples_wrapper_company_text">Салют</p>
                            </div>
                            <div className="col-6 col-lg-4 offset-lg-2">
                                <img className="img-fluid" src={maria} alt="Маруся"/>
                                <p className="your-idea_examples_wrapper_company_text">Маруся</p>
                            </div>
                            <div className="col-6 col-lg-4">
                                <img className="img-fluid" src={alexa} alt="Alexa"/>
                                <p className="your-idea_examples_wrapper_company_text">Alexa</p>
                            </div>
                        </div>
                </div>

                <div className="your-idea_examples_wrapper col-12 col-sm-4 col-md-4 col-lg-4">
                    <p className="your-idea_examples_wrapper_text">Мессенджеры</p>
                    <div className="row p0-m0">
                        <div className="col-6 col-lg-4 offset-lg-2">
                            <img className="img-fluid" src={vk} alt="Вконтакте"/>
                            <p className="your-idea_examples_wrapper_company_text">Вконтакте</p>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src={telegram} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">Telergam</p>
                        </div>
                        <div className="col-6 col-lg-4 offset-lg-2">
                            <img className="img-fluid" src={slack} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">Slack</p>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src={viber} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">Viber</p>
                        </div>
                    </div>
                </div>

                <div className="your-idea_examples_wrapper col-12 col-sm-4 col-md-4 col-lg-4">
                    <p className="your-idea_examples_wrapper_text">Инструменты</p>
                    <div className="row p0-m0">
                        <div className="col-6 col-lg-4 offset-lg-2">
                            <img className="img-fluid" src={just_ai} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">JAICP by Just AI</p>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src={salute} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">SmartMarket</p>
                        </div>
                        <div className="col-6 col-lg-4 offset-lg-2">
                            <img className="img-fluid" src={aimylogic} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">Aimylogic by Just AI</p>
                        </div>
                        <div className="col-6 col-lg-4">
                            <img className="img-fluid" src={aimybox} alt="Алиса"/>
                            <p className="your-idea_examples_wrapper_company_text">Aimybox by Just AI</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="your-idea_bottom-gradient"/>
        </div>
    );
}