import './Profit.css';
import React from "react";


export default function Profit(){
    return (
        <div id="About" className="profit">
            <div className="row profit_h2 p0-m0">
                <div className="col-12">
                    <h2 className="profit_h2_text">Разработка на пике эффективности</h2>
                </div>
            </div>
            <div className="row profit_examples p0-m0">
                <div className="row profit_examples_wrapper p0-m0 col-12 col-sm-6 col-md-4 col-lg-4 justify-content-center">
                    <div className="profit_examples_wrapper_example">
                        <h3>Разработка полного цикла</h3>
                        <p className="profit_examples_wrapper_text p0-m0">— спроектируем механику взаимодействия голосом</p>
                        <p className="profit_examples_wrapper_text p0-m0">— проведем тестирование</p>
                        <p className="profit_examples_wrapper_text p0-m0">— создадим иллюстрации</p>
                        <p className="profit_examples_wrapper_text p0-m0">— разработает саунд</p>
                    </div>
                </div>
                <div className="row profit_examples_wrapper p0-m0 col-12 col-sm-6 col-md-4 col-lg-4 justify-content-center">
                    <div className="profit_examples_wrapper_example">
                        <h3>Платформа JAICP с NLU (понимание естественного языка)</h3>
                        <p className="profit_examples_wrapper_text p0-m0">Разработаем ваш проект с использованием Caila, обучением на ваших логах или с помощью сторонних NLU- модулей</p>
                    </div>
                </div>
                <div className="row profit_examples_wrapper p0-m0 col-12 col-sm-6 col-md-4 col-lg-4 justify-content-center">
                    <div className="profit_examples_wrapper_example">
                        <h3>Многоканальность</h3>
                        <p className="profit_examples_wrapper_text p0-m0">Используйте все доступные каналы коммуникации с клиентами с единым центром управления</p>
                    </div>
                </div>
                    <div className="row justify-content-center profit_examples_wrapper p0-m0 col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="profit_examples_wrapper_example">
                            <h3>Интеграции c внутренними системами</h3>
                            <p className="profit_examples_wrapper_text p0-m0">CRM, ERP,  сторонние БД</p>
                        </div>
                    </div>
                    <div className="row justify-content-center profit_examples_wrapper p0-m0 col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="profit_examples_wrapper_example">
                            <h3>Ваш контур</h3>
                            <p className="profit_examples_wrapper_text p0-m0">Безопасность ваших данных и данных ваших пользователей в вашем контуре</p>
                        </div>
                    </div>
                    <div className="row justify-content-center profit_examples_wrapper p0-m0 col-12 col-sm-6 col-md-4 col-lg-4">
                        <div className="profit_examples_wrapper_example">
                            <h3>Соответствие законодательству</h3>
                            <p className="profit_examples_wrapper_text p0-m0">Разработка проектов, согласно протоколу GDPR и ФЗ-152</p>
                        </div>
                    </div>
            </div>
            <div className="profit_button">
                <a className="btn btn-light" href="#Contacts" role="button">Давайте работать</a>
            </div>
        </div>
    );
}