import './YourApp.css';
import icon1 from './icons/icon1/icon1@2x.png';
import icon2 from './icons/icon2/icon2@2x.png';
import icon3 from './icons/icon3/icon3@2x.png';

export default function YourApp(){
    return (
        <div className="your-app">
            <div className="row your-app_h2 p0-m0">
                <div className="col-12">
                    <h2 className="your-app_h2_text">Индивидуальные решения</h2>
                </div>
            </div>
            <div className="row your-app_examples p0-m0">
                <div className="your-app_examples_wrapper col-12 col-sm-4 col-md-4 col-lg-4">
                    <img className="img-fluid" src={icon1} alt="icon1"/>
                    <p className="your-app_examples_wrapper_text">Боты и виртуальные ассистенты</p>
                </div>
                <div className="your-app_examples_wrapper col-12 col-sm-4 col-md-4 col-lg-4">
                    <img className="img-fluid" src={icon2} alt="icon2"/>
                    <p className="your-app_examples_wrapper_text">Автоматизация бизнес-процессов</p>
                </div>
                <div className="your-app_examples_wrapper col-12 col-sm-4 col-md-4 col-lg-4">
                    <img className="img-fluid" src={icon3} alt="icon3"/>
                    <p className="your-app_examples_wrapper_text">Голосовые игры и квизы</p>
                </div>
            </div>
        </div>
    );
}